.premium_box_container {
    background-color: var(--white);
    padding: 60px 85px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 16px 14px 41px -8px rgba(128, 128, 128, 0.66);
    gap: 30px;
}

.p_wrapper {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.p_benifit {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;
    padding: 20px;
}

.p_benifit p {
    font-size: 20px;
    border-bottom: 1px solid transparent;
}

.p_regular,
.p_premium {
    display: flex;
    flex-direction: column;
    gap: 47px;
    align-items: center;
    padding: 20px;
}

.p_regular h3,
.p_premium h3 {
    font-size: 25px;
    font-weight: bold;
}

.p_icon {
    font-size: 25px;
}

/* .p_premium {
    background-color: var(--black);
    color: var(--premium);
    border-radius: 10px;
} */

.p_pricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p_p_txt {
    width: 60%;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

.pricing_monthly,
.pricing_annualy {
    font-size: 16px;
    line-height: 22px;
    padding: 20px;
    cursor: pointer;
}

.pricing_monthly {
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 30px 20px;
}

.pricing_annualy {
    border-radius: 10px;
    background-color: var(--prime);
}




@media screen {}